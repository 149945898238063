import { generatePath, Params } from 'react-router-dom';

export const generatePathWithQuery = (
  path: string,
  params?: Params,
  query?: Record<string, string>
) => {
  return query
    ? `${generatePath(path, params)}?${new URLSearchParams(query).toString()}`
    : generatePath(path, params);
};
