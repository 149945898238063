import React from 'react';
import { Card } from 'antd';

import styles from './page.module.scss';

interface Props {
  children: React.ReactNode;
}

export const Page: React.FC<Props> = ({ children }) => (
  <div className={styles.page}>
    <Card children={children} />
  </div>
);
