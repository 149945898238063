import React from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { handleError } from 'app/utils/handle-error';

import styles from './top-bar.module.scss';

export const TopBar: React.FC = () => {
  const { t, i18n } = useTranslation();

  const handleClickLithuanian = React.useCallback(() => {
    i18n.changeLanguage('lt').catch(handleError);
  }, [i18n]);

  const handleClickEnglish = React.useCallback(() => {
    i18n.changeLanguage('en').catch(handleError);
  }, [i18n]);

  const handleClickCzech = React.useCallback(() => {
    i18n.changeLanguage('cs').catch(handleError);
  }, [i18n]);

  const handleClickGerman = React.useCallback(() => {
    i18n.changeLanguage('de').catch(handleError);
  }, [i18n]);

  const handleClickSpanish = React.useCallback(() => {
    i18n.changeLanguage('es').catch(handleError);
  }, [i18n]);

  const handleClickFrench = React.useCallback(() => {
    i18n.changeLanguage('fr').catch(handleError);
  }, [i18n]);

  return (
    <div className={styles.topBar}>
      <div className={styles.languages}>
        <Space size="middle">
          <span
            className={styles.languageButton}
            onClick={handleClickLithuanian}
          >
            {t('header.language.lithuanian')}
          </span>
          <span className={styles.languageButton} onClick={handleClickEnglish}>
            {t('header.language.english')}
          </span>
          <span className={styles.languageButton} onClick={handleClickCzech}>
            {t('header.language.czech')}
          </span>
          <span className={styles.languageButton} onClick={handleClickGerman}>
            {t('header.language.german')}
          </span>
          <span className={styles.languageButton} onClick={handleClickSpanish}>
            {t('header.language.spanish')}
          </span>
          <span className={styles.languageButton} onClick={handleClickFrench}>
            {t('header.language.french')}
          </span>
        </Space>
      </div>
    </div>
  );
};
