import React from 'react';
import dayjs from 'dayjs';

import { useTranslation } from 'app/i18n-config';
import { config as appConfig } from 'app/config';
import { UserConfig } from 'app/models/user-config';

import styles from './footer.module.scss';

interface Props {
  config: UserConfig | null;
}

export const Footer: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation();
  const year = React.useMemo(() => dayjs().year(), []);

  return (
    <footer className={styles.footer}>
      <div>
        &copy; {year} {appConfig.name}{' '}
        {config?.termsUrl && (
          <>
            |{' '}
            <a href={config.termsUrl} target="_blank" rel="noreferrer">
              {t('footer.terms')}
            </a>
          </>
        )}
      </div>
      <div className={styles.developer}>
        <a href="https://avakomp.lt/about-us" target="_blank" rel="noreferrer">
          Avakompas
        </a>{' '}
        |{' '}
        <a href="https://avakomp.lt/contactus" target="_blank" rel="noreferrer">
          B2B
        </a>
      </div>
    </footer>
  );
};
