import axios, { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';

export const axiosInstance = axios.create({
  baseURL: `/api`,
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    },
  },
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.href = '/';

      return;
    }

    throw error;
  }
);
