import i18nBase, { StringMap } from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  initReactI18next,
  useTranslation as useTranslationBase,
} from 'react-i18next';

i18nBase
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

// i18n type override until i18next types are figured out
export const i18n = i18nBase as {
  t: (key: string, options?: StringMap) => string;
};

// useTranslation type override until i18next types are figured out
export const useTranslation = useTranslationBase as () => {
  t: (key: string, options?: StringMap) => string;
};
