import React from 'react';
import { Spin } from 'antd';

import { Page } from 'app/components/page/page';

import styles from './page-loader.module.scss';

export const PageLoader: React.FC = () => {
  return (
    <Page>
      <div className={styles.container}>
        <Spin className={styles.loader} />
      </div>
    </Page>
  );
};
