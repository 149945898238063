import React from 'react';
import { Outlet } from 'react-router-dom';

import { MainHeader } from './components/main-header/main-header';
import { PageLoader } from './components/loader/page-loader';
import { Customer } from './models/customer';
import { UserConfig } from './models/user-config';
import { TopBar } from './components/top-bar/top-bar';
import { Footer } from './components/footer/footer';

interface Props {
  customerDetails: Customer;
  config: UserConfig | null;
  onLogOut: () => Promise<void>;
}

export const PrivatePage: React.FC<Props> = ({ customerDetails, config, onLogOut }) => {
  return (
    <div>
      <header>
        <TopBar />
        <MainHeader customer={customerDetails} onLogOut={onLogOut} />
      </header>
      <main>
        <React.Suspense fallback={<PageLoader />}>
          <Outlet />
        </React.Suspense>
      </main>
      <Footer config={config} />
    </div>
  );
};
